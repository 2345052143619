var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"VIEWS h-inherit flex"},[_c('div',{staticClass:"flex-1 bg-base-100"},[_c(_setup.rysqer.active.view.component,{tag:"component",attrs:{"stage":_setup.rysqer.stage,"view":_setup.rysqer.active.view}}),_c(_setup.Splitpanes,{staticClass:"h-full w-full default-theme",attrs:{"push-other-panes":""}},_vm._l((_setup.rysqer.stages),function(stage,index){return _c(_setup.Pane,{key:stage.uuid,staticClass:"relative flex flex-col",attrs:{"size":_setup.rysqer.sizes[index]}},[(stage.busy)?_c('div',{staticClass:"absolute inset-0 z-50 bg-base-100 bg-opacity-50 backdrop-blur-md flex flex-col justicy-center items-center"},[_c('spinner',{staticClass:"flex-1"})],1):_vm._e(),(stage.hero.title || stage.options?.closable)?_c('div',{class:[stage.hero.class]},[_c('div',{staticClass:"pt-6 pb-4 px-4"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex-1 flex flex-wrap justify-between items-start gap-2"},[_c('div',[_c('div',{staticClass:"flex items-center gap-2"},[_c('p',{staticClass:"text-3xl font-bold"},[_vm._v(_vm._s(stage.hero.title))]),(stage.hero.menu && stage.hero.menu.items?.length)?_c('tippy',{ref:(el) => {
													_setup.refTippy = el
												},refInFor:true,scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('button',{staticClass:"d-btn d-btn-xs d-btn-circle"},[_c('icon',{attrs:{"icon":"dots-horizontal","cls":"w-4 h-4 text-primary"}})],1)]},proxy:true}],null,true)},[_c('ul',{staticClass:"min-w-52 d-menu w-full rounded-box"},_vm._l((stage.hero.menu.items.filter(
														(i) => i.condition == null || i.condition == true
													)),function(item,index){return _c('li',{key:index},[_c('a',{on:{"click":() => {
																_setup.refTippy.tip.hide()
																item.link()
															}}},[_c('icon',{attrs:{"icon":item.icon,"cls":item.iconClassr}},[_vm._v(_vm._s(item.label))])],1)])}),0)]):_vm._e()],1),(stage.hero.subtitle)?_c('p',{staticClass:"pt-1 text-base"},[_vm._v(_vm._s(stage.hero.subtitle))]):_vm._e(),(stage.hero.description)?_c('p',{staticClass:"pt-2 text-sm"},[_vm._v(_vm._s(stage.hero.description))]):_vm._e()]),_c('div',{staticClass:"flex items-center gap-2"},[(stage.options?.closable)?_c('button',{staticClass:"d-btn d-btn-sm d-btn-ghost d-btn-circle",on:{"click":function($event){stage.options?.on?.close()}}},[_c('icon',{attrs:{"icon":"close"}})],1):_vm._e()])]),_c('portal-target',{attrs:{"name":`stage-${index}-hero`,"multiple":""}})],1)])]):_vm._e(),(stage.scenes.length > 1)?_c('div',{staticClass:"px-4 py-2 border-y"},[_c('rysqer-stage-tabs',{attrs:{"stage":stage}})],1):_vm._e(),_c('portal-target',{attrs:{"name":`no-scroll-${index}`,"multiple":""}}),(stage.scene)?_c('div',{key:stage.scene.id,staticClass:"relative flex-1 overflow-y-auto",class:stage.scene.class},[_c(stage.scene.component,{key:stage.uuid + stage.scene.id,tag:"component",staticClass:"COMPONENT",attrs:{"stage":stage,"view":stage.scene.view,"scene":stage.scene,"options":stage.scene.options,"resources":stage.scene.resources}}),_vm._l((stage.scene.sectionsArray.filter((a) => {
							return (a.condition == null || a.condition == true) && (a.visible == null || a.visible == true)
						})),function(section){return _c(_setup.RysqerSection,{key:stage.uuid + '-' + stage.scene.id + '-' + section.id,staticClass:"SECTION",attrs:{"stage":stage,"view":stage.scene.view,"scene":stage.scene,"section":section,"options":stage.scene.options,"resources":stage.scene.resources}})})],2):_vm._e()],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
´
<template>
	<div id="app">
		<rysqer-layout> <router-view></router-view></rysqer-layout>
	</div>
</template>

<script setup>
import Vue, { ref, reactive, computed, getCurrentInstance, provide } from 'vue'

import RysqerLayout from '@/layout/Layout'

import { useAuthStore } from '@/stores/auth'
import { useLayoutStore } from '@/stores/layout'
import { useStageStore } from '@/stores/stage'
import { useLibsStore } from '@/stores/libs'
import { useFormattersStore } from '@/stores/formatters'
import { useOptionsStore } from '@/stores/options'

import { useTitle } from '@vueuse/core'

import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import axios from '@/libs/axios'

import { clickToDownload, b64Decode, b64Encode } from '@/libs/functions'

const vm = getCurrentInstance()
const router = vm.proxy.$router
const route = computed(() => vm.proxy.$route)

const title = useTitle()
title.value = 'Rysqer Tool'

if (useAuthStore().authenticated) useOptionsStore().hydrate()

const event = new Vue()

const Rysqer = () => {
	return reactive({
		apps: useLayoutStore().apps,
		active: useLayoutStore().active,
		createApp: useLayoutStore().createApp,
		createView: useLayoutStore().createView,
		createScene: useLayoutStore().createScene,
		stages: useStageStore().stages,
		stage: useStageStore(),
		sizes: useStageStore().sizes,
		breakpoints: useStageStore().breakpoints,
		log: console.log,
		libs: {
			vm: vm,
			toast: vm.proxy.$toast,
			dialog: vm.proxy.$dialog,
			router: router,
			route: route,
			uuid: uuidv4,
			log: console.log,
			table: console.table,
			debounce: useLibsStore().debounce,
			_: _,
			moment: moment,
			axios: axios,
			clickToDownload: clickToDownload,
			b64Decode: b64Decode,
			b64Encode: b64Encode,
			getCanvas: useLibsStore().getCanvas,
			downloadAsPdf: useLibsStore().downloadAsPdf,
			downloadAsJpeg: useLibsStore().downloadAsJpeg,
			getDateTimeString: () => {
				return moment().format('YYYY-MM-DD-hhmmss')
			},
		},
		formatters: useFormattersStore(),
		auth: useAuthStore(),
		can: useAuthStore().can,
		quicklink: {
			uuid: null,
			handle() {
				this.uuid = rysqer.libs.route.params.uuid
			},
			reset(routerName) {
				this.uuid = null
				router.replace({ name: routerName }).catch(() => {})
			},
		},
		options: useOptionsStore(),

		broadcasting: new Vue(),
		events: {
			ticket_changed: 'ticket.changed',
			control_created: 'control.created',
			control_changed: 'control.changed',
			control_instance_changed: 'control.instance.changed',

			risk_created: 'risk.created',
			risk_changed: 'risk.changed',
			risk_instance_changed: 'risk.instance.changed',

			user_created: 'user.created',
			user_changed: 'user.changed',

			tasks_start: 'tasks.start',

			activity_clicked: 'activity.clicked',
		},
	})
}

let rysqer = Rysqer()

provide('rysqer', rysqer)

const needsLogin = () => {
	if (!route.value.meta.subject) return false
	if (rysqer.libs.auth.authenticated) return false

	const canUseRoute = rysqer.libs.auth.check('use.' + route.meta.subject)
	const canManageRoute = rysqer.libs.auth.check('manage.' + route.meta.subject)

	const canPassRoute = canUseRoute || canManageRoute

	return !canPassRoute
}

if (needsLogin()) {
	console.log('needs login...')
	rysqer.libs.router.push({ name: 'login', query: { redirect: to.fullPath } })
}

defineExpose({ rysqer })
</script>

const routes = [
	{
		path: '*',
		name: '404',
		component: () => import('@/apps/auth/Login.vue'),
		meta: {},
	},

	/* --------------------------------------------------- */
	/* AUTH */
	/* --------------------------------------------------- */
	{
		path: '/auth/login',
		name: 'login',
		meta: {},
		component: () => import('@/apps/auth/Login.vue'),
	},

	{
		path: '/auth/not-authorized',
		name: 'not-authorized',
		meta: {},
		component: () => import('@/apps/auth/NotAuthorized.vue'),
	},

	{
		path: '/auth/forgot-password',
		name: 'forgot-password',
		meta: {},
		component: () => import('@/apps/auth/ForgotPassword.vue'),
	},

	{
		path: '/auth/reset-link-sent',
		name: 'reset-link-sent',
		meta: {},
		component: () => import('@/apps/auth/ResetLinkSent.vue'),
	},

	{
		path: '/auth/reset-password',
		name: 'reset-password',
		meta: {},
		component: () => import('@/apps/auth/ResetPassword.vue'),
	},

	/* --------------------------------------------------- */
	/* DASHBOARD */
	/* --------------------------------------------------- */
	{
		path: '/',
		name: 'home',
		component: () => import('@/apps/dashboards/Home.vue'),
		props: { view: 'dashboard' },
		meta: {
			permissions: ['apps.ics.app'],
		},
	},

	/* --------------------------------------------------- */
	/* ICS */
	/* --------------------------------------------------- */
	{
		path: '/ics',
		name: 'ics-default',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/tasks',
		name: 'ics-tasks',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'manage.apps.ics.tasks', 'use.apps.ics.tasks'],
		},
	},

	{
		path: '/ics/tasks/:uuid',
		name: 'ics-tasks-resource',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'manage.apps.ics.tasks', 'use.apps.ics.tasks'],
		},
	},

	{
		path: '/ics/processes',
		name: 'ics-processes',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'processes' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'manage.apps.ics.processes', 'use.apps.ics.processes'],
		},
	},

	{
		path: '/ics/controls',
		name: 'ics-controls',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'controls' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator',
		name: 'ics-coordinator',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/rcm',
		name: 'ics-coordinator-rcm',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'rcm' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/runs',
		name: 'ics-coordinator-runs',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'control_runs' },
		meta: {
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester',
		name: 'ics-tester',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester' },
		meta: {
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	// {
	// 	path: '/ics/management',
	// 	name: 'ics-management',
	// 	component: () => import('@/apps/ics/Home.vue'),
	// 	props: { view: 'management' },
	// 	meta: {
	// 		permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
	// 	},
	// },

	/* --------------------------------------------------- */
	/* RMS */
	/* --------------------------------------------------- */

	{
		path: '/rms',
		name: 'rms-default',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['apps.rms.app'],
		},
	},

	{
		path: '/rms/tasks',
		name: 'rms-tasks',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['apps.rms.app'],
		},
	},

	{
		path: '/rms/tasks/:uuid',
		name: 'rms-tasks-resource',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			permissions: ['apps.rms.app'],
		},
	},

	{
		path: '/rms/registers',
		name: 'rms-registers',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'registers' },
		meta: {
			permissions: ['apps.rms.app'],
		},
	},

	{
		path: '/rms/heatmap',
		name: 'rms-heatmap',

		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'heatmap' },
		meta: {
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app'],
		},
	},

	{
		path: '/rms/admin',
		name: 'rms-admin',

		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'admin' },
		meta: {
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app'],
		},
	},
]

export default routes
